import $ from 'cash-dom';
__webpack_public_path__ = webpack_static_url; // eslint-disable-line
require('../css/main.css');
const USAFACTS = {
  common: {
    init() {
      import('./modules/edge').then(module => {
        module.default();
      });
      import('./modules/defer').then(module => {
        module.default();
      });
      import('./modules/newsletter').then(module => {
        module.default();
      });
      import('./modules/header').then(module => {
        module.default();
      });
      import('./modules/search').then(module => {
        module.default();
      });
      import('./modules/youtube').then(module => {
        module.default();
      });
      import('./modules/accordion').then(module => {
        module.default();
      });
      import('./modules/sharing').then(module => {
        module.default();
      });
      import('@usafacts/frontend-library-cms/src/components/ContentTypes/components/LegacyPublicationChart/legacyUtils/charts').then(module => {
        module.default();
      });
      import('./modules/chartExplorer').then(module => {
        module.default();
      });
      import('./modules/datadog').then(module => {
        module.default();
      });
      import('@usafacts/frontend-library-cms/src/components/ContentTypes/components/LegacyPublicationChart/legacyUtils/chartModifiers').then(module => {
        module.default();
      });
      import('./modules/anchorScrollSpy').then(module => {
        module.default();
      });
      import('./modules/sources-and-footnotes').then(module => {
        module.default();
      });
      import('./modules/wagtail-footnotes').then(module => {
        module.default();
      });
      if ($('.js-truncate').length) {
        import('./modules/truncate').then(module => {
          module.default();
        });
      }
      if ($('.js-chartModal').length) {
        import('./modules/richtextChartPopup').then(module => {
          module.default();
        });
      }
      if ($('iframe').length) {
        import('./modules/iframes').then(module => {
          module.default();
        });
      }
      if ($('.scrolly-section').length) {
        import('./modules/scrollyTelly').then(module => {
          module.default();
        });
      }
    }
  },
  home: {
    init() {
      import('./modules/homepageCallout').then(module => {
        module.default();
      });
    }
  },
  annual10k: {
    init() {
      import('./modules/annual10k').then(module => {
        module.default();
      });
    }
  },
  sotu: {
    init() {
      import('./modules/sotu').then(module => {
        module.default();
      });
      import('./modules/bigpicture').then(module => {
        module.default();
      });
    }
  },
  covidRecoveryHub: {
    init() {
      import('./modules/covidRecoveryHub').then(module => {
        module.default();
      });
    }
  },
  financeTable: {
    init() {
      import('./modules/tables').then(module => {
        module.default();
      });
    }
  }
};
const UTIL = {
  exec(model, action) {
    const modelAction = action === undefined ? 'init' : action;
    if (model !== '' && USAFACTS[model] && typeof USAFACTS[model][modelAction] === 'function') {
      USAFACTS[model][modelAction]();
    }
  },
  init() {
    const sitewrap = document.getElementsByTagName('body')[0];
    const model = sitewrap.getAttribute('data-model');
    const action = sitewrap.getAttribute('data-action');

    // Calls USAFACTS.common.init()
    UTIL.exec('common');
    // Calls USAFACTS.model.init()
    UTIL.exec(model);
    if (action !== undefined) {
      // Calls USAFACTS.model.action()
      UTIL.exec(model, action);
    }
  }
};
document.addEventListener('DOMContentLoaded', () => {
  function windowSizing() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--innerWidth', `${document.body.clientWidth}px`);
    document.documentElement.style.setProperty('--scrollbarWidth', `${window.innerWidth - document.body.clientWidth}px`);
  }
  windowSizing();
  window.addEventListener('resize', () => {
    windowSizing();
  }, {
    passive: true
  });
  UTIL.init();
});